import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useDispatch, useSelector } from 'react-redux';
import { HANDLE_TAB_HOVER_EVENT } from 'actions/types';
import log from 'loglevel';
import { TAB_CONFIG } from 'constants/constants';
import { Link } from 'react-router-dom';
import useTabStyles from 'styles/materialUI/tabStyles';

export default function TabList() {
    const classes = useTabStyles();
    const dispatch = useDispatch();
    let { index } = useSelector(state => state.tabHoverEventReducer);

    const renderTabs = () => {
        log.info(`[TabList]: renderTabs is invoked selectedIndex`)
        return TAB_CONFIG.map(({ index, label, mapKey }) => {
            return (
                <Tab
                    key={index}
                    label={label}
                    component={Link}
                    to={`/${mapKey}`}
                    classes={{ wrapper: classes.tabsWrapper, root: classes.tabRoot }}
                    id={`simple-tab-${index}`}
                    aria-controls={`simple-tabpanel-${index}`}
                />
            );
        });
    }

    log.info(`[TabList]: Rendering TabList Component index = ${index}`);
    return (
        <Tabs value={index} aria-label="simple-tabs">
            {renderTabs()}
        </Tabs>
    );
}
