import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    icon: {
      marginRight: theme.spacing(2),
    },
    heroContent: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
      marginTop: theme.spacing(4),
    },
    cardGrid: {
      paddingBottom: theme.spacing(8),
    },
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    cardMedia: {
      paddingTop: '100%', // 16:9
    },
    cardContent: {
      display: "flex",
      justifyContent:"center",
      alignItems: 'center',
      textAlign: 'center',
      padding: 2,
      paddingBottom: 0,
    },
    footer: {
      backgroundColor: theme.palette.background.paper,
    },
  }));

  
  const cards = [
    {title: '50th', name: 'Ko, Myoungjoo', img: "https://storage.chicagoariranglionsclub.com/members/id_myoungjoo_ko.jpg"},
    {title: '49th', name: 'Sine, Julie', img: "https://storage.chicagoariranglionsclub.com/members/id_julie_sine.jpg"},
    {title: '48th', name: 'Sine, Julie', img: "https://storage.chicagoariranglionsclub.com/members/id_julie_sine.jpg"},
    {title: '47th', name: 'Sine, Julie', img: "https://storage.chicagoariranglionsclub.com/members/id_julie_sine.jpg"},
    {title: '46th', name: 'Kim, Sokon', img: "https://storage.chicagoariranglionsclub.com/members/id_sokon_kim.jpg"},
    {title: '45th', name: 'Park, Sean', img: "https://storage.chicagoariranglionsclub.com/members/id_sean_park.jpg"},
    {title: '44th', name: 'Park, Sean', img: "https://storage.chicagoariranglionsclub.com/members/id_sean_park.jpg"},
    {title: '43rd', name: 'Kim, Sokon', img: "https://storage.chicagoariranglionsclub.com/members/id_sokon_kim.jpg"},
    {title: '42nd', name: 'Kim, Sokon', img: "https://storage.chicagoariranglionsclub.com/members/id_sokon_kim.jpg"},
    {title: '41st', name: 'Shin, Yongsam', img: "https://storage.chicagoariranglionsclub.com/members/default_member.svg"},

    {title: '40th', name: 'No, Kapjoon', img: "https://storage.chicagoariranglionsclub.com/members/id_kapjoon_no.jpg"},
    {title: '39th', name: 'No, Kapjoon', img: "https://storage.chicagoariranglionsclub.com/members/id_kapjoon_no.jpg"},
    {title: '38th', name: 'Shin, Donghun', img: "https://storage.chicagoariranglionsclub.com/members/default_member.svg"},
    {title: '37th', name: 'Shin, Donghun', img: "https://storage.chicagoariranglionsclub.com/members/default_member.svg"},
    {title: '36th', name: 'Suh, Jungyul', img: "https://storage.chicagoariranglionsclub.com/members/id_jungyul_seo.jpeg"},
    {title: '35th', name: 'Kim, Sokon', img: "https://storage.chicagoariranglionsclub.com/members/id_sokon_kim.jpg"},
    {title: '34th', name: 'Kwon, Heewan', img: "https://storage.chicagoariranglionsclub.com/members/default_member.svg"},
    {title: '33rd', name: 'Park, Joobong', img: "https://storage.chicagoariranglionsclub.com/members/default_member.svg"},
    {title: '32nd', name: 'Lee, Yongsoo', img: "https://storage.chicagoariranglionsclub.com/members/id_yongsoo_lee.jpg"},
    {title: '31st', name: 'Lee, Yongsoo', img: "https://storage.chicagoariranglionsclub.com/members/id_yongsoo_lee.jpg"},

    {title: '30th', name: 'No, Kapjoon', img: "https://storage.chicagoariranglionsclub.com/members/id_kapjoon_no.jpg"},
    {title: '29th', name: 'No, Kapjoon', img: "https://storage.chicagoariranglionsclub.com/members/id_kapjoon_no.jpg"},
    {title: '28th', name: 'No, Kapjoon', img: "https://storage.chicagoariranglionsclub.com/members/id_kapjoon_no.jpg"},
    {title: '27th', name: 'Suh, Jungyul', img: "https://storage.chicagoariranglionsclub.com/members/id_jungyul_seo.jpeg"},
    {title: '26th', name: 'Kim, Hoondong', img: "https://storage.chicagoariranglionsclub.com/members/default_member.svg"},
    {title: '25th', name: 'Suh, Kyoungwon', img: "https://storage.chicagoariranglionsclub.com/members/default_member.svg"},
    {title: '24th', name: 'Oh, Kilsung', img: "https://storage.chicagoariranglionsclub.com/members/default_member.svg"},
    {title: '23rd', name: 'Jyon, Yonhwa', img: "https://storage.chicagoariranglionsclub.com/members/default_member.svg"},
    {title: '22nd', name: 'Park, Dongkyun', img: "https://storage.chicagoariranglionsclub.com/members/default_member.svg"},
    {title: '21st', name: 'Lee, Myungho', img: "https://storage.chicagoariranglionsclub.com/members/default_member.svg"},

    {title: '20th', name: 'Kim, Hoondong', img: "https://storage.chicagoariranglionsclub.com/members/default_member.svg"},
    {title: '19th', name: 'Suh, Jungyul', img: "https://storage.chicagoariranglionsclub.com/members/id_jungyul_seo.jpeg"},
    {title: '18th', name: 'Chagon, Chung', img: "https://storage.chicagoariranglionsclub.com/members/id_chagon_chung.jpg"},
    {title: '17th', name: 'Lee, Sungho', img: "https://storage.chicagoariranglionsclub.com/members/default_member.svg"},
    {title: '16th', name: 'Ra, Sangyung', img: "https://storage.chicagoariranglionsclub.com/members/id_sangyung_ra.jpg"},
    {title: '15th', name: 'Kim, Dongsun', img: "https://storage.chicagoariranglionsclub.com/members/default_member.svg"},
    {title: '14th', name: 'Lee, Manwoo', img: "https://storage.chicagoariranglionsclub.com/members/default_member.svg"},
    {title: '13th', name: 'Kim, Hyungjoo', img: "https://storage.chicagoariranglionsclub.com/members/default_member.svg"},
    {title: '12th', name: 'Kim, Hoondong', img: "https://storage.chicagoariranglionsclub.com/members/default_member.svg"},
    {title: '11th', name: 'Kim, Hongsup', img: "https://storage.chicagoariranglionsclub.com/members/default_member.svg"},

    {title: '10th', name: 'Kim, Youngyop', img: "https://storage.chicagoariranglionsclub.com/members/default_member.svg"},
    {title: '9th', name: 'Hwang, Choo', img: "https://storage.chicagoariranglionsclub.com/members/default_member.svg"},
    {title: '8th', name: 'Mansong, Son', img: "https://storage.chicagoariranglionsclub.com/members/default_member.svg"},
    {title: '7th', name: 'Young, Hongdoo', img: "https://storage.chicagoariranglionsclub.com/members/default_member.svg"},
    {title: '6th', name: 'Yoon, Woojoong', img: "https://storage.chicagoariranglionsclub.com/members/default_member.svg"},
    {title: '5th', name: 'Kim, Duhwan', img: "https://storage.chicagoariranglionsclub.com/members/id_duhwan_kim.jpg"},
    {title: '4th', name: 'Koh, Choonghwan', img: "https://storage.chicagoariranglionsclub.com/members/default_member.svg"},
    {title: '3rd', name: 'Lee, Soungsoo', img: "https://storage.chicagoariranglionsclub.com/members/default_member.svg"},
    {title: '2nd', name: 'Kim, Bonghyun', img: "https://storage.chicagoariranglionsclub.com/members/id_bonghyun_kim.jpg"},
    {title: '1st', name: 'Kim, Bonghyun', img: "https://storage.chicagoariranglionsclub.com/members/id_bonghyun_kim.jpg"},
];

export default function President() {

    const styles = useStyles();

  return (

    
    <Grid container spacing={2}>
        {cards.map((card) => (
              <Grid item key={card} xs={4} sm={3} md={2}>
                <Card className={styles.card}>
                <CardContent className={styles.cardContent}>
                    <Typography gutterBottom variant="span" component="span">
                      {card.title}
                    </Typography>
                  </CardContent>
                  <CardMedia
                    className={styles.cardMedia}
                    image={card.img}
                    title={card.name}
                  />
                  <CardContent className={styles.cardContent}>
                    <Typography gutterBottom variant="span" component="span">
                      {card.name}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
        ))}
    </Grid>
  );
}